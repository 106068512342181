/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as ciap from "gcip-iap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setCurrentScreen, setAuth, setConfig, setLoading, setIdToken, setError } from "../slice";
import axios from "axios";
import errorHandle from "../../utilities/errorHandle";

function IAP() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customToken = useSelector(state => state.projectData.customToken);

  useEffect(() => {

    let params = new URLSearchParams(window.location.search);
    let manualToken = params.get("access_token");
    if (manualToken) {
      localStorage.setItem('manual_token', manualToken);
    }

    fetch('/config.json').then((response) => {
      return response.json();
    }).then((config) => {
      dispatch(setConfig(config));
      const expectedApiKey = config.apiKey;
      const authDomain = config.authDomain;
      const weollURL = config.weollURL;
      const functionUrl = config.functionUrl;
      const AuthHandler = {
        getAuth(apiKey, tenantId) {
          // console.log(apiKey)
          let auth = null;
          if (apiKey !== expectedApiKey) {
            throw new Error("Invalid project");
          }
          try {
            auth = firebase.app(tenantId || undefined).auth();
          } catch (e) {
            // console.log(e)
            const app = firebase.initializeApp(
              { apiKey: apiKey, authDomain: authDomain },
              tenantId || "[DEFAULT]"
            );
            auth = app.auth();
            auth.tenantId = tenantId || null;
          }
          dispatch(setAuth(auth));
          return auth;
        },
        startSignIn(auth, selectedTenantInfo) {

          auth
            .getRedirectResult()
            .then(function (result) { })
            .catch(async (error) => {
              if (error.code === 'auth/account-exists-with-different-credential') {
                setCurrentScreen('loading');
                const msLoginEvent = new CustomEvent("msFirstLogin", { detail: { error: error } });
                window.dispatchEvent(msLoginEvent);
              } else {
                errorHandle(error.code);
              }
            });

          return new Promise((resolve, reject) => {

            let manualToken = localStorage.getItem('manual_token');

            if (manualToken) {
              localStorage.removeItem('manual_token');
              auth.signInWithCustomToken(manualToken)
                .then(async (userCredential) => {
                  resolve(userCredential);
                })
                .catch((error) => {
                  // console.log(error);
                });
            }
            else {
              dispatch(setCurrentScreen('signInButtons'));
              window.addEventListener("msFirstLogin", async (e) => {
                try {
                  dispatch(setCurrentScreen('loading'));
                  // dispatch(setLoading(true));
                  // link with cred
                  // const response = await axios.get(`${functionUrl}?token=${manualToken}`);
           
                    const errorCredential = firebase.auth.OAuthProvider.credentialFromError(e.detail.error);
                    const response = await axios.get(`${functionUrl}?token=${errorCredential.accessToken}`);

                    if (response.data && response.data.token) {

                      const userCredential = await firebase.auth().signInWithCustomToken(response.data.token);
                      await userCredential.user.linkWithCredential(errorCredential);

                      resolve(userCredential);
                      // dispatch(setLoading(false));
                  }
            

                } catch (error) {
                  // console.log('3')
                  errorHandle(error.code);
                  dispatch(setCurrentScreen('signInButtons'));
                }

              });
              window.addEventListener("phoneSignIn", (e) => {
                dispatch(setLoading(true))
                e.detail.confirmResult.confirm(e.detail.pin)
                  .then((userCredential) => {
                    resolve(userCredential);
                    dispatch(setLoading(false));
                  }).catch((error) => {
                    errorHandle(error.code);
                    dispatch(setLoading(false));
                  });
              });

            }
          });
        },
        async completeSignOut() {
          return;
        },
        showProgressBar() {
          dispatch(setCurrentScreen('loading'));
        },
        hideProgressBar() {
          // loading => sign in => loading => sign in 
          /*
          let params = new URLSearchParams(window.location.search);
          let manualToken = params.get("access_token");
          if(!manualToken) {
            dispatch(setCurrentScreen('signInButtons'));
          }
          */
        },
        handleError(error) {
          const E01 = "Authentication token has expired. Please visit the URL that redirected you to this page again to restart the authentication process.";
          const E02 = "Invalid API key";
          if (error.message === E01) {
            window.location.href = weollURL;
          }
          if (error.message === E02 && process.env.NODE_ENV !== 'development') {
            window.location.href = weollURL;
          }
        },
        async processUser(user) {

          //auth bitmeden kullanıcı üstünde custom işler yapma yeri
          const idTokenResult = await user.getIdTokenResult();
          // console.log(idTokenResult, "<idTokenResult")
          const firstLogin = idTokenResult.claims.firstLogin;
          dispatch(setIdToken(idTokenResult.token));

          if (firstLogin) {
            navigate('/welcome');
            try {
              await axios.get(`/welcome/start?t=${idTokenResult.token}`);
              dispatch(setIdToken(""));
              return user;
            } catch (error) {
              dispatch(setError('UNKNOWN_ERROR'));
            }
          } else {
            return user;
          }
        },
      };

      const ciapInstance = new ciap.Authentication(AuthHandler);
      ciapInstance.start();
    })
  }, [])

  return null;
}

export default IAP;
