import { configureStore } from '@reduxjs/toolkit';
import projectDataSlice from '../slice';

const Store = configureStore({
	reducer: {
		projectData: projectDataSlice,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default Store;
