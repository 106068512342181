/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LangChangeHandler from './components/weoll-language-switch';
import IAP from "./core/actions/setIAP";
import MainLayout from "./layout/mainLayout";
import Loading from "./layout/loading";

const Welcome = lazy(() => import('./layout/welcome'));
const Registration = lazy(() => import('./layout/registration'));
const Login = lazy(() => import('./layout/login'));

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const localDefaultLng = localStorage.getItem('language');
    const urlParams = new URLSearchParams(window.location.search);
    // burada bize parametre geldikten sonra ? kalkmalı. şu an için yine locale yazılacak. 
    const lang = urlParams?.get('lang');
    if (lang) {
      i18n.changeLanguage(lang);
      localStorage.setItem('language', lang);
    } else {
      if (localDefaultLng && localDefaultLng === 'tr') {
        i18n.changeLanguage('tr');
        localStorage.setItem('language', 'tr');
      } else {
        i18n.changeLanguage('en');
        localStorage.setItem('language', 'en');
      }
    }
  }, []);

  return (
    <>
      <LangChangeHandler />
      <MainLayout>
        <Routes>
          <Route element={<><IAP /><Outlet /></>}>
            <Route
              path="/"
              element={
                <Suspense fallback={
                  <Loading
                    headerText={t('WELCOME')}
                    subText={t('JUST_A_FEW_SECONDS')}
                    emoji />
                }>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="/welcome"
              element={
                <Suspense fallback={
                  <Loading
                    headerText={t('WELCOME')}
                    subText={t('ACCOUNT_CREATION_MSG')}
                    subTextComponents={{
                      br: <br />,
                      span: <span
                        className="text-center"
                        style={{
                          fontWeight: '500',
                          fontSize: 16,
                          lineHeight: '22px',
                          color: '#707070'
                        }}></span>
                    }}
                  />
                }>
                  <Welcome />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/registration"
            element={
              <Suspense fallback={
                <Loading
                  headerText={t('WELCOME')}
                  subText={t('JUST_A_FEW_SECONDS')}
                  emoji
                />
              }>
                <Registration />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </MainLayout>
    </>
  );
}

export default App;
