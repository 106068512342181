import React from 'react';
import PropTypes from 'prop-types';
import HeaderText from '../../components/headerText';
import SubHeaderText from '../../components/subHeaderText';
import { Trans } from "react-i18next";
import weollLogo from '../../assets/images/logos/weoll-sm-logo.svg';
import './styles.css';

function Loading({ headerText, subText, subTextComponents, emoji }) {
	return (
		<div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center gap-4">
			<div className={`d-flex flex-column text-center ${(headerText && subText) && 'gap-1'}`}>
				{headerText && (<HeaderText>{headerText}</HeaderText>)}
				{subText && (
					<SubHeaderText>
						<div className={emoji ? 'd-flex align-items-center gap-1' : ''}>
							<Trans i18nKey={subText} components={subTextComponents} />
							{emoji && (
								<span style={{ fontSize: 20 }}>
									&#x1f60a;
								</span>
							)}
						</div>
					</SubHeaderText>)}
			</div>
			<div className="d-flex align-items-center justify-content-center">
				<img
					src={weollLogo}
					alt="smallLogo"
					className="logoAnim"
				/>
			</div>
		</div>
	);
}

Loading.defaultProps = {
	headerText: "",
	subText: "",
	subTextComponents: {},
	emoji: false
};

Loading.propTypes = {
	headerText: PropTypes.string,
	subText: PropTypes.string,
	subTextComponents: PropTypes.object,
	emoji: PropTypes.bool
};

export default Loading;
