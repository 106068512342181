import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import Store from './core/actions/setReduxStore';
import './assets/styles/app.scss';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

const weoll = (
	<BrowserRouter>
		<Provider store={Store}>
			<App />
		</Provider>
	</BrowserRouter>
)

root.render(process.env.NODE_ENV === 'development' ? weoll : (<React.StrictMode>{weoll}</React.StrictMode>)
);
reportWebVitals();
