import { /* createAsyncThunk */ createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';

const initialState = {
	loading: false,
	currentScreen: "loading",
	app: null,
	auth: {},
	phoneConfirm: {},
	config: {},
	error: "",
	phoneNumber: null,
	idToken: "",
};

export const projectDataSlice = createSlice({
	name: 'projectData',
	initialState,
	reducers: {
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setStatus(state, action) {
			state.status = action.payload;
		},
		setCurrentScreen(state, action) {
			state.currentScreen = action.payload;
			state.error = '';
		},
		setAuth(state, action) {
			state.auth = action.payload;
		},
		setApp(state, action) {
      state.app = action.payload;
		},
		setphoneConfirm(state, action) {
			state.phoneConfirm = action.payload;
		},
		setConfig(state, action) {
			state.config = action.payload;
		},
		setError(state, action) {
			state.error = action.payload;
		},
		setPhoneNumber(state, action) {
			state.phoneNumber = action.payload;
		},
		setIdToken(state, action) {
			state.idToken = action.payload;
		},
		setCustomToken(state, action) {
			state.customToken = action.payload;
		}
	},
	extraReducers: (builder) => {
	},
});


export const {
	setLoading, setCurrentScreen, setAuth, setphoneConfirm, setConfig, setError, setPhoneNumber, setIdToken, setCustomToken, setApp
} = projectDataSlice.actions;

export default projectDataSlice.reducer;
