import React from 'react';
import { useTranslation } from 'react-i18next';
import instagramLogo from '../../../../assets/images/logos/instagram.svg';
import linkedinLogo from '../../../../assets/images/logos/linkedin.svg';

function Footer() {
	const { t } = useTranslation();
	return (
		<div className="footer-wrapper">
			<div className="copyright">{t('COPYRIGHT')}</div>
			<div>
				<div className="social-media-icons">
					<a href="https://www.instagram.com/weoll.tr/" target="_blank" rel="noreferrer">
						<img src={instagramLogo} alt="Instagram" />
					</a>
					<a href="https://www.linkedin.com/company/weoll/" target="_blank" rel="noreferrer">
						<img src={linkedinLogo} alt="Linkedin" />
					</a>
				</div>
			</div>
		</div>
	);
}

export default Footer;
