import React from 'react';
import { useTranslation } from 'react-i18next';
import WeollFullLogo from '../../../../assets/images/logos/weoll-lg-logo.svg';

function PoweredBy() {
	const { i18n } = useTranslation();
	const currentLanguage = i18n.language;
	return (
		<div className="d-flex justify-content-center align-items-center" style={{ minHeight: 56 }}>
			<div className="d-flex align-items-end gap-1">
				<div style={{ color: '#707070', fontSize: '11px', fontWeight: '400', lineHeight: 1 }}>Powered by</div>
				<img
					onClick={() => {
						if (currentLanguage !== 'tr') {
							window.open('https://weoll.com/')
						} else {
							window.open('https://weoll.com.tr/')
						}
					}}
					src={WeollFullLogo}
					style={{ height: 18, cursor: 'pointer' }}
					alt="Weoll"
				/>
			</div>
		</div>
	);
}

export default PoweredBy;
