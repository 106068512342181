import React from 'react';

function SubHeaderText({ children }) {

  return (
    <div style={{
      fontWeight: 300,
      fontSize: 16,
      color: '#707070',
    }}>
      {children}
    </div >
  );
}

export default SubHeaderText;