import React from 'react';
import lgLogo from '../../../../assets/images/logos/weoll-lg-logo.svg';

function Header() {
	return (
		<div className="d-flex justify-content-center">
			<img
			style={{
				display: 'block',
				height: 36,
				marginTop: 32,
				marginBottom: 32,
			}}
				src={lgLogo}
				alt="Weoll"
			/>
		</div>
	);
}

export default Header;