import React from 'react';

function HeaderText({ children }) {

  return (
    <div style={{
      fontWeight: '800',
      fontSize: 24,
      color: '#21314E',
    }}>
      {children}
    </div >
  );
}

export default HeaderText;