import React from 'react';
import Footer from './subComponents/footer';
import Header from './subComponents/header';
import PoweredBy from './subComponents/poweredBy';

function MainLayout({ children }) {
	return (
		<div id="mainWrapper">
			<div id="mainContainer">
				<div id="firstWrapper" className="d-flex">
					<div className="weoll-img d-flex flex-column flex-grow-1" style={{ background: 'rgb(0, 77, 113' }}>
						<div className="weoll-bg-img" style={{ flexGrow: 1 }}>
						</div>
						<Footer />
					</div>
				</div>

				<div id="secondWrapper">
					<div className="d-flex flex-column justify-content-between w-100 h-100 position-relative">
						<Header />
						<div className="d-flex flex-column gap-3 align-items-center">
							{children}
						</div>
						<PoweredBy />
					</div>
				</div>
			</div>
		</div>
	);
}

export default MainLayout;
