/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

function ChangeLangDropDown() {
	const availableLanguages = ['tr', 'en']
	const { t, i18n } = useTranslation();
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		if (lng === 'tr') {
			localStorage.setItem('language', 'tr');
		} else {
			localStorage.setItem('language', 'en');
		}
	};
	const currentLanguage = i18n.language;

	return (
		<div className="position-absolute" style={{ top: '18px', right: '18px', zIndex: '999' }}>
			<Dropdown>
				<Dropdown.Toggle variant="light" className="d-flex align-items-center lang-menu-btn">
					<span className="me-1 text-uppercase">{t(currentLanguage)}</span>
					<svg className="arrow" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g>
							<path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
						</g>
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{availableLanguages?.length > 0
						&& availableLanguages.map((lang) => (
							<Dropdown.Item
								key={lang}
								onClick={() => {
									changeLanguage(lang); 
									localStorage.setItem('language', lang);
								}}>
								<img
									className="me-3"
									src={require(`../../assets/images/flags/${lang}.png`)}
									alt={lang.toUpperCase()}
									style={{ width: 20, height: 20 }}
								/>
								{t(`${lang}-full`)}
							</Dropdown.Item>
						))}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
}

export default ChangeLangDropDown;
